import { Action } from '@ngrx/store';
import { NewUserDetails } from '@touch-apps/user-model';

export enum UserActionTypes {

  LOAD_USERS = '[User] Load Users',
  LOAD_USERS_SUCCESS = '[User] Load Users success',
  LOAD_USERS_FAILURE = '[User] Load Users failure',
  CREATE_USER = '[User] Create user',
  CREATE_USER_SUCCESS = '[User] Create user success',
  CREATE_USER_FAILURE = '[User] Create user failed',
  DELETE_USER = '[User] Delete user',
  DELETE_USER_SUCCESS = '[User] Delete user success',
  DELETE_USER_FAILURE = '[User] Delete user failed',
}

export class LoadUsers implements Action {
  readonly type = UserActionTypes.LOAD_USERS;

  constructor() {
  }
}

export class LoadUsersSuccess implements Action {
  readonly type = UserActionTypes.LOAD_USERS_SUCCESS;

  constructor(public users: any) {
  }
}

export class LoadUsersFailure implements Action {
  readonly type = UserActionTypes.LOAD_USERS_FAILURE

  constructor(public error: any) {
  }
}

export class CreateUser implements Action {
  readonly type = UserActionTypes.CREATE_USER;

  constructor(public newUserDetails: NewUserDetails) {
  }
}

export class CreateUserSuccess implements Action {
  readonly type = UserActionTypes.CREATE_USER_SUCCESS;

  constructor(public user: any) {
  }
}

export class CreateUserFailure implements Action {
  readonly type = UserActionTypes.CREATE_USER_FAILURE;

  constructor(public error: any) {
  }
}

export class DeleteUser implements Action {
  readonly type = UserActionTypes.DELETE_USER;

  constructor(public userId: string) {
  }
}

export class DeleteUserSuccess implements Action {
  readonly type = UserActionTypes.DELETE_USER_SUCCESS;

  constructor(public user: any) {
  }
}

export class DeleteUserFailure implements Action {
  readonly type = UserActionTypes.DELETE_USER_FAILURE;

  constructor(public error: any) {
  }
}

export type UserActions =
  | LoadUsers | LoadUsersSuccess | LoadUsersFailure
  | CreateUser | CreateUserSuccess | CreateUserFailure
  | DeleteUser | DeleteUserSuccess | DeleteUserFailure;



